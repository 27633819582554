<template>
  <div class="counter" :class="{'is-active': isActive}">
    <div @click="minus" class="counter__minus">-</div>
    <div @click="changeActiveStatus" class="counter__counter-circle">{{ amount }}</div>
    <div @click="plus" class="counter__plus">+</div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    name: "Counter",

    data() {
      return {
        isActive: false,

      }
    },
    props: {
      amount: {},
      sku: {},
    },
    computed: {
      ...mapState([
        ''
      ])
    },
    methods: {
      ...mapMutations([
        'changeCartItemAmount'
      ]),

      ...mapActions([
        'recalcCart'
      ]),

      plus() {
        const data = {
          sku: this.sku,
          amount: this.amount + 1
        }
        this.changeCartItemAmount(data)
      },
      minus() {
        if (this.amount > 0) {
          const data = {
            sku: this.sku,
            amount: this.amount - 1
          }
          this.changeCartItemAmount(data)
        }
      },

      changeActiveStatus() {
        this.isActive = !this.isActive
      },
    }
  }
</script>

<style scoped>

</style>