<template>
  <div class="cart">
    <section class="cart__screen">

      <div @click="backToHome" class="cart__logo img-w">
        <img src="@/assets/img/logo.png" alt="">
      </div>

      <div class="cart__page-logo img-w">
        <img src="@/assets/img/cart-page-logo.svg" alt="">
      </div>

      <div class="cart__df">
        <form id="order-form" class="cart-form">
          <div class="cart-form__row">
            <div class="cart-form__input-box">
              <label class="cart-form__label">Вас зовут</label>
              <input v-model="user.name" :class="{'is-empty': showErrors && !user.name}" class="cart-form__input cart-form__input--1" type="text" name="name" placeholder="Имя или имя и фамилия, как удобнее">
            </div>
            <div class="cart-form__input-box">
              <label class="cart-form__label">Ваш номер телефона</label>
              <the-mask v-model="phone" @input="isPhoneErrored = false" :class="{'is-empty': isPhoneErrored}" name="phone" class="cart-form__input cart-form__input--1" :placeholder="'+7 ___ ___ __ __'" :mask="['+7 (###) ###-##-##']" />
            </div>
            <div class="cart-form__input-box">
              <label class="cart-form__label">E-mail</label>
              <input v-model="user.email" @input="isEmailErrored = false" :class="{'is-empty': isEmailErrored}" class="cart-form__input cart-form__input--1" type="text" name="email" placeholder="Для чека, а не для спама">
            </div>
          </div>


          <div class="cart-form__row">
            <div class="cart-form__input-box">
              <label class="cart-form__label">Город</label>
              <input v-model="user.city" class="cart-form__input" type="text" name="city" value="Москва" disabled>
              <div class="cart-form__locked-ico img-w">
                <svg viewBox="-64 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m336 192h-16v-64c0-70.59375-57.40625-128-128-128s-128 57.40625-128 128v64h-16c-26.453125 0-48 21.523438-48 48v224c0 26.476562 21.546875 48 48 48h288c26.453125 0 48-21.523438 48-48v-224c0-26.476562-21.546875-48-48-48zm-229.332031-64c0-47.0625 38.269531-85.332031 85.332031-85.332031s85.332031 38.269531 85.332031 85.332031v64h-170.664062zm0 0"/>
                </svg>
              </div>
            </div>
            <div class="cart-form__input-box">
              <label class="cart-form__label">Улица</label>
              <input v-model="user.street" :class="{'is-empty': showErrors && !user.street}" class="cart-form__input cart-form__input--2" type="text" name="street" placeholder="Улица">
            </div>
            <div class="cart-form__input-box">
              <label class="cart-form__label">Номер дома</label>
              <input v-model="user.building" maxlength="10" :class="{'is-empty': showErrors && !user.building}" class="cart-form__input" type="text" name="building" placeholder="Номер дома">
            </div>
            <div class="cart-form__input-box">
              <label class="cart-form__label">Квартира</label>
              <input v-model="user.flat" class="cart-form__input" maxlength="10" type="text" name="flat" placeholder="Номер квартиры">
            </div>
          </div>

          <div class="cart-form__row">
            <div class="cart-form__input-box">
              <label class="cart-form__label">Время доставки:</label>
              <input v-model="user.date" class="cart-form__input cart-form__input--3-1" type="date" name="time1" :min="minDate" max="2022-01-20">
            </div>
            <div class="cart-form__input-box">
              <label class="cart-form__label">&nbsp;</label>

              <select v-model="user.time2" name="time2" class="cart-form__select">
                <option value="1">c 9 до 11</option>
                <option value="2">c 11 до 13</option>
                <option value="3">c 13 до 15</option>
                <option value="4">c 15 до 17</option>
                <option value="5">c 17 до 19</option>
                <option value="6">c 19 до 21</option>
              </select>
            </div>
            <div class="cart-form__input-box cart-form__input-box--s0">
              <input class="cart-form__checkbox" hidden type="checkbox" name="silent" id="cart-form-silent">
              <label for="cart-form-silent">
                Попросить курьера <span class="nobr">не звонить</span> в домофон
              </label>
            </div>
            <div class="cart-form__input-box cart-form__input-box--button">
              <button @click.prevent="toggleDelivery" class="button cart-form__button">Про доставку</button>
            </div>
          </div>

          <div class="cart-form__row">
            <div class="cart-form__input-box">
              <label class="cart-form__label">Промокод:</label>

              <div class="cart-form__str-btn">
                <input v-model="user.promocode" :disabled="isPromocodeUsed" class="cart-form__input">

                <button @click.prevent="checkPromocode" class="button cart-form__button cart-form__button--promocode">Применить</button>
              </div>

            </div>
          </div>
        </form>

        <div class="cart__switch">
          <div class="cart-list">

            <div class="cart-list__row" v-for="item in cart" :key="item.id">

              <Counter :amount="item.amount" :sku="item.sku"></Counter>

              <div class="cart-list__item">{{ item.name }} Black Rabbit,
                <span class="nobr">{{ item.size }}</span> - {{ item.price }} Р
              </div>
            </div>

            <div class="cart-list__delivery" v-if="deliveryPrice != 0">
              + доставка 200 рублей
            </div>

          </div>

          <div class="cart-footer">
            <div class="cart-footer__sum">
              Стоимость заказа:
              <span :class="{'is-lined': isPromocodeUsed}">
                {{ cartSum + deliveryPrice }}p.
              </span>
              <span class="cart-footer__discounted-price" v-if="isPromocodeUsed">
                {{ Math.trunc(cartSum*discount) + deliveryPrice }}p.
              </span>
            </div>
          </div>
        </div>

      </div>

      <div class="cart__bot">
        <div class="cart-footer__switch">
          <span class="cart-footer__switch-label cart-footer__switch-label--left">Оплата картой на сайте</span>
          <Toggle v-model="isCash"/>
          <span class="cart-footer__switch-label cart-footer__switch-label--right">Наличными курьеру</span>
        </div>

        <div class="cart-footer__button-box">
          <button @click.prevent="send"
                  class="button cart-footer__button"
                  :class="{'is-disabled': cartSum == 0}">
            ЗАКАЗАТЬ
          </button>
        </div>

        <p class="cart-footer__note">
          Нажимая "Заказать", вы даете согласие на <a :href="`${publicPath}personal-data.pdf`" class="cart-footer__link" target="_blank">обработку персональных данных</a>
        </p>
      </div>

      <div class="delivery" :class="{'is-visible': delivery}">
        <div @click="toggleDelivery" class="delivery__close img-w">
          <img src="@/assets/img/close.png" alt="">
        </div>

        <div class="delivery__title">ЗАКАЗ <br>
          И ДОСТАВКА</div>

        <p class="delivery__text">
          Мы <b>бесплатно доставим</b> по Москве Ваш <b>первый заказ</b> на подгузники или подгрузники- трусики Black Rabbit.
        </p>
        <p class="delivery__text">
          Уверены, что Вы сразу станете нашим постоянным клиентом, ведь подгузники Black Rabbit действительно разительно отличаются от всех остальных.
        </p>
        <p class="delivery__text">
          Мы готовы предложить Вам на второй и все последующие заказы <b>бесплатную доставку</b> по Москве от <b>2-х коробок</b>. Если ваш заказ меньше, то согласуем стоимость доставки удобной логистической службой на этапе его оформления.
        </p>

        <p class="delivery__note delivery__note--first">
          В самое ближайшее время подгузники и трусики Black Rabbit будут доступны на
          <b>Ozon</b> и <b>Wildberries</b>.
        </p>
        <p class="delivery__note">
          Надеемся, это очень упростит процесс
          покупки и доставки в регионы.
        </p>
      </div>


    </section>


    <div class="ty" :class="{'is-visible': ty}">
      <div class="ty__logo img-w">
        <img src="@/assets/img/logo.png" alt="">
      </div>

      <div class="ty__text-column">
        <div class="ty__title">ГОТОВО!</div>

        <p class="ty__text">
          <b>Ваш заказ принят!</b>
        </p>
        <p class="ty__text">
          Скоро с вами свяжется курьер.
          Если у вас возникнут вопросы, вы
          всегда можете написать в службу поддержки <a class="ty__link" href="mailto:hello@black-rabbit.us">hello@black-rabbit.us</a> или в direct Instagram!
        </p>
        <p class="ty__text">
          Спасибо, что выбрали <b>Black Rabbit</b>!
        </p>
        <div class="ty__button-box">
          <button @click="reset" class="button">НА ГЛАВНУЮ</button>
        </div>
      </div>
    </div>

    <modal name="error" :adaptive="true">
      <p v-html="serverError"></p>
    </modal>
  </div>
</template>


<script>
  import { mapState, mapMutations } from 'vuex'
  import Toggle from '@/components/Toggle'
  import Counter from '@/components/Counter.vue'
  import {TheMask} from 'vue-the-mask'
  import axios from 'axios'

  export default {
    data() {
      return {
        isCash: false,

        minDate: null,

        phone: null,

        user: {
          name: null,
          phone: null,
          email: null,
          city: 'Москва',
          street: null,
          building: null,
          flat: null,
          date: null,
          time2: '1',
          promocode: null,
        },

        publicPath: process.env.BASE_URL,

        delivery: false,

        overlay: {
          wait: 'Ваш заказ обрабатывается. Пожалуйста подождите, скоро вы будете перенаправлены на страницу оплаты.'
        },

        serverError: null,

        deliveryPrice: 0,
        discount: 1,

        isPromocodeUsed: false,

        isSent: false,

        isSending: false,
        ty: false,
        showErrors: false,

        isEmailErrored: false,
        isPhoneErrored: false,
      }
    },
    name: 'Cart',
    computed: {
      ...mapState([
        'cart', 'cartSum', 'apiUrl'
      ]),

    },
    props: {

    },
    components: {
      Toggle, Counter, TheMask,
    },
    methods: {
      ...mapMutations([
        'recalc'
      ]),

      backToHome() {
        this.$router.push('/')
      },

      toggleDelivery() {
        this.delivery = !this.delivery
      },

      checkPromocode() {
        if (this.isPromocodeUsed) return false

        let data = new FormData()

        data.append('promocode', this.user.promocode)

        axios.post(this.apiUrl + '/api/checkPromocode', data)
          .then((response) => {
            if (response.data.success) {
              if (response.data.data.exists) {
                this.discount = (100 - response.data.data.discount)/100

                this.isPromocodeUsed = true
              } else {
                this.user.promocode = null
              }
            } else {
              this.user.promocode = null
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },


      checkDeliveryPrice() {
        let data = new FormData()

        data.append('phone', this.phone)

        axios.post(this.apiUrl + '/api/clientExists', data)
          .then((response) => {

            if (response.data.success) {
              if (response.data.data.exists && this.cartSum < 2500) {
                this.deliveryPrice = 200
              } else {
                this.deliveryPrice = 0
              }
            }

          })
          .catch((error) => {
            console.log(error)
          })
      },

      sendGtm(toStorage) {
        let goodsType = []
        let goodsSize = []

        const arr = Object.values(this.cart)

        arr.forEach(item => {
          goodsType.push(`${item.name} ${item.size}`)
          goodsSize.push(item.size)
        })

        let label = 'наличные'

        if (toStorage) {
          label = 'на сайте'
        }

        const gtmData = {
          'event':'brshop',
          'eventCategory':'order',
          'eventAction':'buy',
          'eventLabel': label, // тип оплаты, которую выбрал пользователь
          'goodsType': goodsType.join(','), // в случае, если товаров несколько указать тип через разделитель ","
          'goodsSize': goodsSize.join(','), // в случае, если товаров несколько указать тип через разделитель ","
          'price': this.cartSum
        }

        window.dataLayer.push(gtmData);

        /*if (!toStorage) {
          window.dataLayer.push(gtmData);
        } else {
          const cookie = JSON.stringify(gtmData)
          this.$cookies.set('ogtm', cookie)
        }*/

      },


      send() {
        if (this.cartSum == 0) return false
        if (this.isSending) return false
        if (!this.validateEmail(this.user.email)) {
          this.isEmailErrored = true
          window.scrollTo(0, 0)
          return false
        }
        if (this.phone) {
          if (this.phone.length != 10) {
            this.isPhoneErrored = true
            window.scrollTo(0, 0)
            return false
          }
        } else {
          this.isPhoneErrored = true
          window.scrollTo(0, 0)
          return false
        }

        if (this.checkForm()) {
          this.isSent = true
          this.isSending = true

          let items = []

          Object.keys(this.cart).forEach(item => {
            let obj = {}
            obj["sku"] = item
            obj["count"] = this.cart[item].amount

            items.push(obj)
          })

          const data = {
            "name": this.user.name,
            "email": this.user.email,
            "phone": this.phone.replace(/\D+/g,""),
            "city": 'Москва',
            "prepaid": !this.isCash,
            "time1": this.user.date,
            "time2": this.user.time2,
            "street": this.user.street,
            "building": this.user.building,
            "flat": this.user.flat,
            "silent": this.user.silent,
            "promocode": this.user.promocode,
            "items": items,
          }

          const json = JSON.stringify(data)

          axios.post(this.apiUrl + '/api/order', json)
            .then((response) => {
              this.isSending = false

              if (response.data.success) {
                if (!this.isCash) {
                  this.sendGtm(true)
                  location.href = response.data.data.redirect_url
                } else {
                  this.sendGtm(false)
                  this.ty = true
                }
              } else {
                this.serverError = response.data.error

                this.$modal.show('error')
              }

            })
            .catch((error) => {
              this.isSending = false

              this.serverError = error

              this.$modal.show('error')
            })
        } else {
          window.scrollTo(0, 0)
          this.showErrors = true
        }
      },

      reset() {
        location.href = '/'
      },

      checkForm() {
        let flag = true

        let inputs = Object.assign({}, this.user)

        delete inputs.flat
        delete inputs.phone
        delete inputs.promocode

        Object.values(inputs).forEach((item) => {
          if (item === null) {
            flag = false
          }
        })

        return flag
      },


      setDate() {
        let currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        let day = currentDate.getDate()
        let month = currentDate.getMonth() + 1
        month = month.toString()

        let today = day - 1

        if (month.toString().length == 1) {
          month = '0' + month
        }
        if (day.toString().length == 1) {
          day = '0' + day
        }
        if (today.toString().length == 1) {
          today = '0' + today
        }

        let year = currentDate.getFullYear()

        this.user.date = `${year}-${month}-${day}`
        this.minDate = this.user.date
      },

      validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },

    },

    watch: {
      phone: function(val) {
        if (val.length > 9) {
          this.checkDeliveryPrice()
        }
      },

      cartSum: function(val) {
        if (val < 5000 && this.phone) {
          this.checkDeliveryPrice()
        }
      },
    },

    mounted() {
      window.scrollTo(0, 0)

      this.recalc()

      this.setDate()


      if (this.$route.query.ty != undefined) {
        this.ty = true

        /*const cookie = this.$cookies.get('ogtm')


        if (cookie != undefined) {
          window.dataLayer.push(cookie)
          this.$cookies.remove('ogtm')
        }*/
      }
    }
  }
</script>